import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ITreeOptions,
  IActionMapping,
  TREE_ACTIONS,
  TreeNode,
  TreeModel
} from '@circlon/angular-tree-component';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { QuerySheetModel } from '../../models/querysheet-mstr.model';
import { restructureQueryTreeNodes } from './queryTreeUtils';
import { GlobalService } from '../../services/global.service';
import { LinkService } from '../../services/link.service';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ADMINISTRATOR, PROJECT_MANAGER, EXPLORER_MEMBER, QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER,
   TEAM_ADMIN_PLUS_EXPLORER_MEMBER, TEAM_ADMIN, QUERYSHEET_ADMIN, DEMO_USER} from '../../constants/userRoles';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { DeleteModalComponent } from '../../components/modals/delete/delete.component';
import { UsersTableComponent } from 'src/app/components/users-table/users-table.component';
import { QuerysheetUsersComponent } from './querysheet-users/querysheet-users.component';
import { TreeComponent } from '../../components/tree/tree.component';

@Component({
  selector: 'app-querysheet-details',
  templateUrl: './querysheet-details.component.html',
  styleUrls: ['./querysheet-details.component.scss']
})
export class QuerysheetDetailsComponent implements OnInit {
  @ViewChild(UsersTableComponent)
  @ViewChild(TreeComponent) tree: any;
  private usersTable: UsersTableComponent;
  public querysheetDetails: QuerySheetModel = null;
  private querySheetId = null;
  public selectedQuery = {};
  public selectedGroup = {};
  public isLoading: boolean = false;
  private dataSub: Subscription;
  public qsAllowedRoles:  Array<String> = [ADMINISTRATOR, PROJECT_MANAGER, QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER, TEAM_ADMIN_PLUS_EXPLORER_MEMBER, TEAM_ADMIN];
  public user: any;
  public isSaveDisabled: boolean = true;
  public isEditDisabled: boolean = false;
  public enablePublishAfterEdit: boolean = true;
  public showAddQueryForm: boolean = false;
  public showAddGroupForm: boolean = false;
  public nodes: any = [];
  public tenantName: any;
  public actionOnNode: any = null; // node is used when user tries to add peer query/child query/group
  public actionType: null;
  public addedEditedGroupsQueryIds = [];
  public currentQueryCount: number = 0;
  public _userPermissions: any;
  public disableManageExplorerMemberBtn : boolean = false;
  public disableFldsForQSA_Plus_EM : boolean = false; 

  actionMapping: IActionMapping = {
    mouse: {
      expanderClick: (tree, node: TreeNode, $event) => {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      },
      click: (tree, node, $event) => {
        this.clearUpNodeFormData();
        this.handleNodeClickFunction(node, $event);
      },
      drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
        const dropPosition = to.index; // drop position in tree ( top and Group = 0, peer swapping >= 1)
        //const targetNestingLevel = to.parent.level; // to get the Group Levels
        const treeRef = this.tree.getTreeRef(); // to update tree menu

        if (from.data.isGroup && to.parent.level >= 3) {
          return false;
        }

        if (to && to.parent && to.parent.data && to.parent.data?.children) {
          // Before removing "removeAndInsertQuery" get the nodeObject of dropPosition

          const nodeObj = to.parent.data.children[dropPosition];
          const removeAndInsertQuery = from.parent.data.children.splice(from.index, 1);

          if (removeAndInsertQuery.length > 0) {
            removeAndInsertQuery[0].parentId = to.parent.id;
          }

          if (to.dropOnNode) {
            to.parent.data.children.push(removeAndInsertQuery[0]);
            this.isSaveDisabled = false;

          } else {
            //drop position last and first of queries
            if ((to.parent.data.children.length + 1 === dropPosition) || dropPosition === 0) {
              to.parent.data.children.splice(dropPosition, 0, removeAndInsertQuery[0])
            }

            //drop position for In between
            else {
              if (nodeObj) {
                const index = to.parent.data.children.findIndex((value) => value.id == nodeObj.id);
                to.parent.data.children.splice(index, 0, removeAndInsertQuery[0])
              } else {
                to.parent.data.children.push(removeAndInsertQuery[0]);
              }
            }
            this.isSaveDisabled = false;
          }
          treeRef.treeModel.update();
        }
      }
    }
  };

  handleNodeClickFunction(node, event) {
    setTimeout(() => {
       // For leaf nodes i.e. queries, show the details
       if (!node.hasChildren) {
        this.showAddQueryForm = this.isEditDisabled;
        this.showAddGroupForm = false;
        this.selectedQuery = node.data;
        this.selectedGroup = {};
      } else {
        this.showAddGroupForm = event.target.nodeName == 'MAT-ICON' ? false : this.isEditDisabled;
        this.showAddQueryForm = false;
        this.selectedGroup = node.data;
        this.selectedQuery = {};
      }
    }, 0);
  }

  /* getDepthOfChildrenLevel is a recursive function, that calculates the depth of a tree-like structure.
 */
  getDepthOfChildrenLevel(currentElement, currentDepthLevel) {
    currentDepthLevel ++;

      for (let childElement of currentElement.children || []) {
      if (childElement.isGroup) {
        currentDepthLevel = this.getDepthOfChildrenLevel(childElement, currentDepthLevel);        
        if (currentDepthLevel >= 3) {
          return currentDepthLevel
        }
      }
    }
    return currentDepthLevel;
  }

  options: ITreeOptions = {
    allowDrag: !this.isSaveDisabled,
    allowDrop: (element, { parent, index }) => {
      // when user drag and drop on same item of draged one, in this case making disable
      if (element.data.id === parent.data.id) {
        return false
      }
      if (element.data.isGroup) {
        let elementValue = this.getDepthOfChildrenLevel(element.data, 0);
        if (elementValue + parent.level > 3) {
          return false
        }
      }
      return true;
    },
    actionMapping: this.actionMapping
  };

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.showAddGroupForm || this.showAddQueryForm || !this.isSaveDisabled) {
      $event.returnValue = true;
    }
  }

  public hasUnsavedChanges(): boolean {
    return (this.showAddGroupForm || this.showAddQueryForm || !this.isSaveDisabled)
  }

  constructor(
    private globalService: GlobalService,
    public link: LinkService,
    public router: Router,
    public route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
  ) {
    this.user = authService.getLoggedInUser();
    this.tenantName = authService.getTenantName();
  }

  ngOnInit(): void {
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.querysheetDetails = this.globalService.getQuerysheet();

    this.dataSub = this.route.queryParamMap.subscribe((params: Params) => {
      /* Scenario: Query sheet details Page refresh,
                  taking id from route and fetching all the details */
      this.querySheetId = params.get('querysheetId');
    });

    this.fetchQueryTreeDetails();   

    if (this.user.role.rolename === EXPLORER_MEMBER || this.user.role.rolename === QUERYSHEET_ADMIN || this.user.role.rolename === DEMO_USER) {
      this.disableManageExplorerMemberBtn = true;
    }

    if (this.user.role.rolename === QUERYSHEET_ADMIN_PLUS_EXPLORER_MEMBER) {
      this.disableFldsForQSA_Plus_EM = true;
    }
  }

  /**
  * Destroy event handler
  */
  ngOnDestroy() {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  clearUpNodeFormData() {
    // Clearing up the required states
    this.selectedGroup = {};
    this.selectedQuery = {};
    this.showAddGroupForm = false;
    this.showAddQueryForm = false;
  }

  public fetchQueryTreeDetails() {
    this.isLoading = true;
    this.globalService
      .fetchQuerySheetDetails(this.querysheetDetails?.querySheetId || this.querySheetId)
      .then(response => {
        this.nodes = restructureQueryTreeNodes(response.querytree);
        this.querysheetDetails = this.globalService.getQuerysheet();
        this.currentQueryCount = this.querysheetDetails.querycount;
        this.isLoading = false;
        // Resetting selected Query
        this.selectedQuery = {};
      })
      .catch(error => {
        this.isLoading = false;
      });
  }

  public deleteQuerySheet() {
    const url = 'querysheets/delete';
    const params = { 'ids': [this.querysheetDetails?.querySheetId] };

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: 'querySheet',
      customMessage: "querysheetManagement.querySheetDeleteConfirmation",
      notAllowed: !this.querysheetDetails?.querySheetId
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.isLoading = true;
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              this.isLoading = false;
              // Redirecting to query sheets management/list page
              this.link.goToQuerySheetsManagement();

              // Querysheet/s has been deleted successfully
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.querysheetDeletedSuccessfully'
                )
              );
            }
          );
        }
      }
    );
  }

  /**
    * Load modal to manage users
    */
  public manageExplorer() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options: any = {};
    options.data = {
      querySheetId: this.querySheetId || this.querysheetDetails?.querySheetId
    };
    const modalWindowRef = this.modalService.open(
      QuerysheetUsersComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(role => { });
  }


  public createNodeTemplate(displayOrder, queryName, queryText, queryQuestion, parentNodeId) {

    const parentId = parentNodeId || Math.ceil(Math.random() * 1000000);
    const singlequerynodeTemplate: any = {
      "isGroup": false,
      "id": Math.ceil(Math.random() * 1000000),
      "name": queryName,
      "text": queryText,
      "type": "Semantic",
      "parentId": parentId,
      "displayOrder": displayOrder + 1,
      "hasChildren": false
    }

    if (queryQuestion?.length) {
      singlequerynodeTemplate.qna = {
                        "isGroup": false,
                        "id": Math.ceil(Math.random() * 1000000),
                        "name": queryName,
                        "text": queryQuestion,
                        "type": "QnA",
                        "parentId": parentId,
                        "displayOrder": 1,
                        "hasChildren": false
                      }
    }

    if (!parentNodeId) {
      // If parentNodeId is null this means its a 1st level query node
      singlequerynodeTemplate.group = {
                        "isGroup": true,
                        "id": parentId,
                        "name": "nan",
                        "text": null,
                        "type": null,
                        "parentId": null,
                        "displayOrder": displayOrder + 1,
                        "hasChildren": true
                      }
    }
    return singlequerynodeTemplate
  }

  public updateQueryTree = (queryObject) => {
    if (!queryObject?.isGroup) {
      const treeRef = this.tree?.getTreeRef();
      // If its a 1st level query node, it will have group object
      const addedOnGroup = this.actionOnNode?.isGroup && this.actionType == 'child query';
      const parentNodeId = addedOnGroup ? this.actionOnNode.id : (this.actionOnNode?.group ? null : this.actionOnNode?.parentId);
      const queryNode = this.createNodeTemplate(this.nodes.length, queryObject.queryName, queryObject.queryText, queryObject.queryQuestion, parentNodeId);
      if (treeRef) {
        if (this.actionOnNode) {
          // Getting parent id of node on which action took place
          if (parentNodeId) {
            const parentNode = treeRef.treeModel.getNodeById(parentNodeId);
            // If node getting added on group, add it to last index, otherwise after the query on which the action took place
            const index = addedOnGroup ? parentNode.data.children.length : parentNode.data.children.indexOf(this.actionOnNode);
            index > -1 && parentNode.data.children.splice(index + 1, 0, queryNode);
          } else {
            // Getting added on root node of the tree
            const index = treeRef.treeModel.nodes.indexOf(this.actionOnNode);
            index > -1 && treeRef.treeModel.nodes.splice(index + 1, 0, queryNode);
          }
        } else {
          treeRef.treeModel.nodes.push(queryNode);
        }
        treeRef.treeModel.update();
      } else {
        // Case where user adding node on blank tree
        const node_temp = [...this.nodes, queryNode];
        this.nodes = node_temp;
      }
      
      this.addedEditedGroupsQueryIds.push(queryNode.id);
      this.showAddQueryForm = false;
      this.actionOnNode = null;
      // Incrementing the current query count on add
      this.currentQueryCount += 1;
      this.isSaveDisabled = false;
    }
  }

  public editTreeNode = (editNode) => {
    const treeRef = this.tree.getTreeRef();
    const treeNode = treeRef.treeModel.getNodeById(editNode.id).data;
    const parentNode = treeNode.parentId ? treeRef.treeModel.getNodeById(treeNode.parentId) : null;
    if (parentNode != null) {
      parentNode.data.children[parentNode.data.children.indexOf(treeNode)] = editNode;
    } else {
      treeRef.treeModel.nodes[treeRef.treeModel.nodes.indexOf(treeNode)] = editNode;
    }
    this.addedEditedGroupsQueryIds.push(editNode.id);
    treeRef.treeModel.update();

    // Clearing up the required states
    this.clearUpNodeFormData();
    this.isSaveDisabled = false;
  }

  public deleteTreeNode = (event, nodeType) => {
    const treeRef = this.tree.getTreeRef();
    const selectedNode: any = nodeType == 'group' ? this.selectedGroup : this.selectedQuery;
    const deleteNode = treeRef.treeModel.getNodeById(selectedNode.id).data;
    const parentNode = deleteNode.parentId ? treeRef.treeModel.getNodeById(deleteNode.parentId) : null;
    if (parentNode != null) {
      parentNode.data.children.splice(parentNode.data.children.indexOf(deleteNode), 1);
    } else {
      treeRef.treeModel.nodes.splice(treeRef.treeModel.nodes.indexOf(deleteNode), 1);
    }
    treeRef.treeModel.update();

    if (nodeType == 'query') {
      // Decrementing the current query count on delete
      this.currentQueryCount -= 1;
    }

    // Clearing up the required states
    this.clearUpNodeFormData();
    this.isSaveDisabled = false;
  }

  /**
    * Load Add Group Form Modal
    */
  public showAddGroupFormModal(event) {
    event.stopPropagation();
    this.selectedGroup = {};
    this.showAddQueryForm = false;
    this.showAddGroupForm = true;
  }

  /**
    * Load Query Form modal
    */
  public showAddQueryFormModal = (event) => {
    event.stopPropagation();
    this.selectedQuery = {};
    this.showAddGroupForm = false;
    this.showAddQueryForm = true;
  }

  public nodeActionClicked = (params) => {
    params.event.stopPropagation();
    // Clearing up the required states
    this.clearUpNodeFormData();

    setTimeout(() => {
      if (['child query', 'peer query'].includes(params.action)) {
        this.showAddQueryForm = true;
      } else if (['child group'].includes(params.action)) {
        this.showAddGroupForm = true;
      } 
      this.actionOnNode = params.node.data;
      this.actionType = params.action;
    });
  }

  /**
   * Emitted data from child component is storing and updating to query list
   */
  public setGroupObject(event: { value }) {
    const currentData: any = {
      "isGroup": event.value.isGroup,
      "id": event.value.id || null,
      "name": `${event.value.name}`,
      "text": event.value.text,
      "type": event.value.type,
      "parentId": event.value.parentId || null,
      "displayOrder": event.value.displayOrder,
      "hasChildren": event.value.hasChildren,
      "children": []
    }


    const treeRef = this.tree?.getTreeRef();
    if (treeRef) {
      // Getting parent id of node on which action took place
      const addedOnGroup = this.actionOnNode?.isGroup && this.actionType == 'child group';
      const parentNodeId = addedOnGroup ? this.actionOnNode.id : null;
      if (this.actionOnNode) {
        const parentNode = treeRef.treeModel.getNodeById(parentNodeId);
        // Add child group to last the index
        parentNode.data.children.push(currentData);
      } else {
        treeRef.treeModel.nodes.push(currentData);
      }
      treeRef.treeModel.update();
    } else {
      this.nodes = [...this.nodes, { ...currentData }];
    }

    this.addedEditedGroupsQueryIds.push(currentData.id);
    this.showAddGroupForm = false;
    this.isSaveDisabled = false;
  }

  public cancelQueryModal(event:{value}) {
    this.showAddQueryForm = event.value;
    this.selectedQuery = {};
    this.actionOnNode = null;
  }
  public cancelGroupModal(event: {value}) {
    this.showAddGroupForm = event.value;
    this.selectedGroup = {};
    this.actionOnNode = null;
  }


  public saveQueriesToDB() {
    const url = `querysheets/`;
    const params = { 'querytree': this.nodes }
    const headers = {
      observe: 'response'
    };

    // Setting the isLoading flag to true
    this.isLoading = true;

    this.apiService
      .put(
        url,
        this.querysheetDetails?.querySheetId || this.querySheetId,
        JSON.stringify(params),
        '',
        true,
        headers,
        false,
        true
      )
      .subscribe(
        event => {
          this.addedEditedGroupsQueryIds = [];
          if (this.querysheetDetails?.isPublished) {
            this.publishQuerySheet();
          } else {
            this.showAddQueryForm = false;
            this.showAddGroupForm = false;
            this.enablePublishAfterEdit = true;
            this.isSaveDisabled = true;
            this.isEditDisabled = false;
            this.isLoading = false;
            // Fetch updated query sheet details
            this.fetchQueryTreeDetails();
          }
        },
        async error => {
          this.showAddQueryForm = false;
          this.showAddGroupForm = false;
          this.isLoading = false;
          this.enablePublishAfterEdit = true;
          this.isSaveDisabled = true;
          this.isEditDisabled = false;
          this.alertService.error(this.translate.transform('querysheetDetails.errorWhileUpdatingQS'));
        }
      );
  }

  /**
   * Publish querysheet
   */
   public publishQuerySheet(): void {
    const url = `querysheets/bulk_edit_publish_status`;
    const data = {
      querySheetIds: [this.querysheetDetails.querySheetId],
      is_published: true
    }
    this.apiService.post(url, data, '')
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.alertService.success(
            this.translate.transform(
              'querysheetDetails.progressToast'
            ),
            true
          );
          //set currentTab in global to queySheetList
          this.globalService.setCurrentTab('querysheetList');
          //Navigate to Query Sheet List screen
          this.isSaveDisabled = true;
          this.router.navigate([
            `${this.tenantName}/querysheet-management`
          ]);
        },
        (error: any) => {
          console.log('error occured', error);
          this.isLoading = false;
        }
      );
  }

  public editQueriesBtnClicked() {
    this.options.allowDrag = true;
    this.isEditDisabled = true;
    this.enablePublishAfterEdit = false;
    this.selectedQuery = {};
    this.selectedGroup = {};
  }

  public setIsLoadingFlag(flag: boolean) {
    this.isLoading = flag;
  }
}
