import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user-mstr.model';
import { UserModalComponent } from '../modals/user-modal/user-modal.component';
import { DeleteModalComponent } from '../modals/delete/delete.component';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GlobalService } from 'src/app/services/global.service';
import { RoleModel } from 'src/app/models/role.model';
import { ThemeComponentModule } from 'src/app/theme/theme-components/ThemeComponentModule';
import * as _rolesConst from '../../constants/userRoles';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, OnChanges {
  @Input() paginationType: string;
  @Input() params: {} = [];

  //QuerySheetUsers
  @Input() querySheetId: string = '';
  @Input() toAssignUsersList: Array<Number>;
  @Input() toUnassignUsersList: Array<Number>;

  public user: any;
  public loading: boolean;
  public backgroundLoading: boolean;
  public pagination: PaginationModel;
  public columns: any[];
  public settingTable: any;
  public rows: {};
  public showFilters: boolean = false;

  // Const
  public _userPermissions: any;

  // Project user
  public projectId: number;
  public assigned: number;

  // Query Sheet user
  public qsassigned: number;

  public filters: any = [];
  public labelFilters: any[];
  public totalUsers: number;

  public rolesList: RoleModel[];

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  // Validation assign users to querysheet
  @Output() handleSubmit = new EventEmitter();
  // Assign users to querysheet
  @Output() assignUsers = new EventEmitter();

  // Validation assign users
  @Output() assignFromTable = new EventEmitter();
  public projectsIds: string;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private modalService: NgbModal,
    private router: Router,
    private translate: TranslatePipe,
    private global: GlobalService,
    private changeDetector: ChangeDetectorRef
  ) {
    this._userPermissions = this.global.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    if (
      ['assignedprojectusers', 'unassignedprojectusers'].includes(
        this.paginationType
      )
    ) {
      this.showFilters = true;
    }
    this.rolesList = this.global.getRoles();
    if (this.rolesList.length === 0 && !this.global.passedWatcherUtils) {
      this.global.watchUtils().subscribe(() => {
        this.rolesList = this.global.getRoles();
      });
    }
  }

  ngOnChanges() {
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    this.setTableType();
    this.getColumns();
    this.getSettingTable();
    this.getUsers();
  }

  changePaginationChild(event) {
    this.paginationType = event;
    this.pagination = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    this.setTableType();
    this.getColumns();
    this.getSettingTable();
  }

  /**
   * Get users from storage or database
   */
  private getUsers() {
    const usersPage = this.paginationService.getPage(this.paginationType);
    const defaultPage = this.paginationService.getDefaultPagination(
      this.paginationType
    );
    if (
      usersPage &&
      usersPage.data.length &&
      usersPage.page === 1 &&
      usersPage.order_by === defaultPage.order_by &&
      usersPage.desc === defaultPage.desc
    ) {
      // If users are loaded, show them and hide spinner
      this.rows = usersPage;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If users aren't loaded, show spinner
      this.loading = true;
    }
    // Always request users from db
    if (
      !['assignedprojectusers', 'unassignedprojectusers'].includes(
        this.paginationType
      )
    ) {
      this.getUsersFromDB();
    }
  }

  /**
   * Get users from database
   */
  private getUsersFromDB() {
    const params = this.getParams();
    this.apiService.get('users/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: UserModel[];
      }) => {
        //Maintain Querysheet users selection For Page Navigation
        if (this.querySheetId &&
          (this.toAssignUsersList.length > 0 ||
            this.toUnassignUsersList.length > 0)) {
          data["data"].forEach(obj => {
            if (this.toAssignUsersList.includes(obj["userid"])) {
              obj["isRowSelected"] = 1
            }
            else if (this.toUnassignUsersList.includes(obj["userid"])) {
              obj["isRowSelected"] = 0
            }
          })
        }
        this.rows = data;
        this.totalUsers = data.total_elements;
        this.paginationService.setPage(this.paginationType, data);
        this.pagination = this.paginationService.setPagination(
          this.paginationType,
          {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          }
        );
        this.loading = false;
        this.backgroundLoading = false;
      },
      () => {
        this.loading = false;
        this.backgroundLoading = false;
      }
    );
  }

  /**
   * Return loaded columns with the information of each column
   * of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectUsers.username',
        name: 'username',
        orderBy: 'username'
      },
      {
        header: 'Email',
        name: 'email'
      },
      {
        header: 'projectUsers.role',
        title: 'projectUsers.role',
        name: 'role',
        second: 'rolename',
        tags: this.getTagsIcon.bind(this),
        type: 'roles',
        class: ''
      },
      {
        header: 'projectDocument.date',
        name: 'modifieddate',
        type: 'date',
        format: 'lll',
        orderBy: 'modifieddate'
      }
    ];
    this.setAdditionalColumns();
  }

  /**
   * Function to add differents columns
   * to the table depending on which
   * component is the parent
   */
  private setAdditionalColumns() {
    if (this.paginationType === 'users') {
      const editableUser: any[] = [
        {
          header: 'projectUsers.username',
          name: 'username',
          type: 'link',
          clickLink: this.onEditUser.bind(this),
          orderBy: 'username'
        }
      ];
      this.columns.splice(0, 1, ...editableUser);
    } else if (this.paginationType === 'reviewers') {
      const selectionUser: any[] = [
        {
          header: 'projectUsers.username',
          name: 'username',
          type: 'link',
          clickLink: this.onSelectUser.bind(this),
          orderBy: 'username'
        }
      ];
      this.columns.splice(0, 1, ...selectionUser);
      this.columns.splice(3);
    } else if (this.paginationType === 'explorers') {
      const selectionUser: any[] = [
        {
          header: 'projectUsers.username',
          name: 'username',
          type: 'link',
          clickLink: this.onSelectUser.bind(this),
          orderBy: 'username'
        }
      ];
      this.columns.splice(0, 1, ...selectionUser);
      this.columns.forEach(obj => {
        if (obj.name == 'email') {
          obj.orderBy = 'email'
        }
      })
      this.columns = this.columns.filter(function( obj ) {
        return obj.name !== 'role';
      });
    }
  }

  /**
   * Returns wether it should render checkbox or not
   * depending of status of row
   * @param row row to check its status
   */
  public shouldRender(row) {
    let shouldRender = true;
    if (
      row.role.roleid === _rolesConst.ID_ADMINISTRATOR &&
      this.paginationType !== 'users'
    ) {
      shouldRender = false;
    }
    return shouldRender;
  }

  /**
   * Return loaded SettingTable with the general settings for
   * the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      hasEditPermissions: this.hasEditPermissions(),
      renderFunction: this.shouldRender.bind(this),
      getDataFromDB: this.getUsersFromDB.bind(this),
      responsiveTitle: {
        label: 'username'
      }
    };
    this.setAdditionalOptions();
  }

  public getTagsIcon(tags: any) {
    return 'fa fa-info';
  }

  /**
   * Set all the global variables for show the proper list of docs
   * depending on the parent component
   */
  public setTableType() {
    switch (this.paginationType) {
      case 'unassignedprojectusers':
        this.projectId = this.params['projectId'];
        this.assigned = 0;
        break;
      case 'assignedprojectusers':
        this.projectId = this.params['projectId'];
        this.assigned = 1;
        break;
      case 'reviewers':
        this.projectsIds = this.params['projectsids'];
        break;
      default:
        break;
    }
  }

  /**
   * Function to set the params to
   * pass to the API call depending
   * on the parent component
   * TODO ADD IN reviwers projecstids: this.projectsIds, page_size: 5
   */
  private getParams() {
    switch (this.paginationType) {
      case 'users':
        return { ...this.pagination, ...this.filters, complete: true };
      case 'unassignedprojectusers':
        return {
          ...this.pagination,
          ...this.filters,
          projectid: this.projectId,
          complete: true
        };
      case 'assignedprojectusers':
        return {
          ...this.pagination,
          ...this.filters,
          projectid: this.projectId,
          complete: true
        };
      case 'reviewers':
        return {
          ...this.pagination,
          ...this.filters,
          complete: true,
          page_size: 5
        };
      case 'explorers':
        return {
          ...this.pagination,
          ...this.filters,
          page_size: 10,
          roleid:[_rolesConst.ID_DEMO_USER],
          query:'',
          complete: true,
          querysheetid: this.querySheetId
        };
      default:
        return { ...this.filters, complete: true, page_size: 5 };
    }
  }

  /**
   * Function to add differents custom options
   * to the setting table depending on wich
   * component is the parent
   */
  private setAdditionalOptions() {
    switch (this.paginationType) {
      case 'users':
        this.settingTable['dataId'] = 'userid';
        this.settingTable['hasSelect'] = 'true';
        this.settingTable['actionsOnSelected'] = [
          {
            click: this.deleteSelectedUsers.bind(this),
            class: 'fa fa-trash',
            title: 'projectUsers.deleteSelected'
          }
        ];
        this.settingTable['actionsOnResponsive'] = [
          {
            click: this.onEditUser.bind(this),
            class: 'fa fa-edit'
          },
          {
            click: this.deleteUser.bind(this),
            class: 'fa fa-trash'
          }
        ];
        break;
      case 'explorers':
        this.settingTable['dataId'] = 'userid';
        this.settingTable['hasRowSelectOptions'] = true
        this.settingTable['onRowSelection'] = this.selectQSUser.bind(this);
        break;
      case 'unassignedprojectusers':
        this.settingTable['dataId'] = 'userid';
        this.settingTable['hasSelect'] = 'true';
        this.settingTable['actionsOnSelected'] = [
          {
            click: this.assignSelectedUsers.bind(this),
            class: +this.assigned ? 'fa fa-user-times' : 'fa fa-user-plus',
            title: +this.assigned
              ? 'projectUsers.unassignSelected'
              : 'projectUsers.assignSelected'
          }
        ];
        this.settingTable['actionsOnResponsive'] = [
          {
            click: this.assignUser.bind(this),
            class: +this.assigned ? 'fa fa-user-times' : 'fa fa-user-plus'
          }
        ];
        break;
      case 'assignedprojectusers':
        this.settingTable['dataId'] = 'userid';
        this.settingTable['hasSelect'] = 'true';
        this.settingTable['actionsOnSelected'] = [
          {
            click: this.assignSelectedUsers.bind(this),
            class: +this.assigned ? 'fa fa-user-times' : 'fa fa-user-plus',
            title: +this.assigned
              ? 'projectUsers.unassignSelected'
              : 'projectUsers.assignSelected'
          }
        ];
        this.settingTable['actionsOnResponsive'] = [
          {
            click: this.assignUser.bind(this),
            class: +this.assigned ? 'fa fa-user-times' : 'fa fa-user-plus'
          }
        ];
        break;
      case 'reviewers':
        this.settingTable['dataId'] = 'userid';
        break;
      default:
        this.settingTable['paginationClass'] = 'relative-pag';
        break;
    }
  }

  /**
   * Function to switch the permissions
   * between the differents parent components
   */
  private getAdditionalPerm() {
    switch (this.paginationType) {
      case 'users':
      case 'explorers':
        return this.user.isadmin;
      case 'unassignedprojectusers':
        return (
          this.user.isadmin ||
          this.authService.hasPermission([
            this._userPermissions.ASSIGN_USERS_TO_PROJECTS
          ])
        );
      case 'assignedprojectusers':
        return (
          this.user.isadmin ||
          this.authService.hasPermission([
            this._userPermissions.ASSIGN_USERS_TO_PROJECTS
          ])
        );
      default:
        return true;
    }
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Check if user has edit permissions
   */
  private hasEditPermissions() {
    return this.user && this.getAdditionalPerm();
  }

  /**
   * Load modal to edit user
   */
  public onCreateUser() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options = {
      type: 'new',
      action: 'create',
      user: {
        userid: null,
        username: '',
        email: '',
        role: { roleid: null },
        privacyNoteAccepted: 0
      }
    };
    const modalWindowRef = this.modalService.open(
      UserModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(userResult => {
      const {
        action,
        user: { userid, ...userParams }
      } = userResult;
      // TODO: Remove if train model permission is included in the modal
      if (action !== 'close') {
        this.apiService
          .post(
            'admin/users',
            JSON.stringify(userParams),
            'components.modal.user.createSuccessMsg'
          )
          .subscribe(() => {
            const tenantName = this.authService.getTenantName();
            this.router.navigate([`/${tenantName}`, 'admin']);
            this.getUsers();
          });
      }
    });
  }

  // START ADMIN --> USERS FUNCTIONS BLOCK
  /**
   * Load modal to edit user
   */
  public onEditUser(user: UserModel) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options = {
      type: 'edit',
      action: 'update',
      user: user
    };
    const modalWindowRef = this.modalService.open(
      UserModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(userResult => {
      const {
        action,
        user: { userid, ...userParams }
      } = userResult;
      if (action !== 'close') {
        this.loading = true;
        this.apiService
          .put(
            'admin/users/',
            userid,
            JSON.stringify(userParams),
            'components.modal.user.editSuccessMsg'
          )
          .subscribe(() => {
            this.loading = true;
            this.getUsersFromDB();
          });
      }
    });
  }

  /**
   * Assign multiple users to Query sheet
   */
  public selectQSUser(user) {
    this.handleSubmit.emit(user);
  }

  /**
   * Load modal to confirm user(s) removal
   */
  private deleteUsersModal(usersids: number[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'user',
      notAllowed: usersids.length < 1
    };

    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: usersids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => { }
    );
  }

  /**
   * Delete single user
   */
  public deleteUser(user) {
    this.deleteUsersModal([user.userid]);
  }

  /**
   * Delete multiple users
   */
  public deleteSelectedUsers(selectedItems) {
    this.deleteUsersModal(selectedItems);
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.apiService
      .post(
        'admin/users/delete',
        requestbody,
        'components.modal.delete.actionSuccess'
      )
      .subscribe(
        object => {
          // Remove deleted users from view while request from db
          this.rows = this.rows['data'].filter(
            el => requestbody.ids.indexOf(el.userid) < 0
          );
          this.paginationService.changePagination('users', 'page', 1);
          this.getUsersFromDB();
        },
        error => {
          this.loading = false;
        }
      );
  }
  // END ADMIN --> USERS FUNCTIONS BLOCK

  // START PROJECT --> USERS FUNCTIONS BLOCK
  /**
   * Assign/unassign users from project
   */
  public onAssign(usersids: number[]) {
    const payload = {
      projectid: this.projectId,
      userids: usersids
    };
    const assignAction = +this.assigned ? 'unassign' : 'assign';
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      ConfirmModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'user',
      message: this.translate.transform(
        `components.modal.assign.userproject${assignAction}`
      )
    };

    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          this.sendRequest(payload, assignAction);
        }
      },
      reason => { }
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendRequest(requestbody: any, assignAction: string) {
    const url = `users/${assignAction}`;
    // TODO: Create proper message
    this.apiService.post(url, requestbody, '', '').subscribe(
      () => {
        this.getUsersFromDB();
      },
      error => {
        this.loading = false;
        console.error('Error changing assigned user(s) to project');
      }
    );
  }

  /**
   * Change multiple users assignation
   */
  public assignSelectedUsers(selectedItems) {
    this.onAssign(selectedItems);
  }

  /**
   * Change single user assignation
   */
  public assignUser(user) {
    this.onAssign([user.userid]);
  }
  // END PROJECT --> USERS FUNCTIONS BLOCK

  // START VALIDATION LIST --> ASIGN USER FUNCTIONS BLOCK
  /**
   * Action when click on the user name
   * To select it and set it on the modal component
   * @param user
   */
  public onSelectUser(user) {
    this.assignFromTable.emit(user);
  }
  // END VALIDATION LIST --> ASIGN USER FUNCTIONS BLOCK

  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
   */
  public onSearch(filters) {
    this.onBackgroundLoadingChange(true);
    this.pagination = this.paginationService.getDefaultPagination(this.paginationType);

    switch (this.paginationType) {
      case 'users':
        this.filters = {
          username:
            filters['username'] !== undefined ? filters['username'] : '',
          email: filters['email'] !== undefined ? filters['email'] : '',
          roleid: filters['roleid'] !== undefined ? filters['roleid'] : ''
        };
        break;
      case 'reviewers':
        this.filters = {
          username:
            filters['username'] !== undefined ? filters['username'] : '',
          email: filters['email'] !== undefined ? filters['email'] : '',
          roleid: filters['roleid'] !== undefined ? filters['roleid'] : ''
        };
        break;
      case 'explorers':
        this.filters = {
          username:
            filters['username'] !== undefined ? filters['username'] : '',
          email: filters['email'] !== undefined ? filters['email'] : '',
          qsassigned: filters['qsassigned'] !== undefined ? filters['qsassigned'] : ''
        };
        break;
      case 'assignedprojectusers':
        this.filters = {
          username:
            filters['username'] !== undefined ? filters['username'] : '',
          email: filters['email'] !== undefined ? filters['email'] : '',
          roleid: filters['roleid'] !== undefined ? filters['roleid'] : '',
          assigned: filters['assigned'] !== undefined ? filters['assigned'] : ''
        };
        break;
      case 'unassignedprojectusers':
        this.filters = {
          username:
            filters['username'] !== undefined ? filters['username'] : '',
          email: filters['email'] !== undefined ? filters['email'] : '',
          roleid: filters['roleid'] !== undefined ? filters['roleid'] : '',
          assigned: filters['assigned'] !== undefined ? filters['assigned'] : ''
        };
        break;
    }

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        let value;
        switch (f) {
          case 'roleid':
            value = this.roleIdToString(this.filters[f]);
            f = this.translate.transform('admin.' + f);
            break;
          case 'assigned':
          case 'qsassigned':
            value = this.translate.transform(
              'admin.assignement.' + this.filters[f]
            );
            f = this.translate.transform('admin.' + f);
            break;
          default:
            value = this.filters[f];
        }
        return { name: f, value };
      });

    this.getUsersFromDB();
  }

  /**
   * Return label translation of role filter
   */
  private roleIdToString(roleId): string {
    let selectedRole;
    this.rolesList.forEach(role => {
      if (role.roleid === roleId) selectedRole = role;
    });
    return this.translate.transform(
      'components.modal.user.roleList.' + selectedRole.rolename
    );
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  public cleanIndividualFilter(filter: string, value:any){
    if(filter === 'Assignment' && value === "Not assigned users"){
      this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
      this.labelFilters.push({name: "Assignment", value: "Assigned users"})

      filter = 'unassignedprojectusers'

      this.filterEvent.next(filter)
      this.filters['assigned'] = '1';
      this.onBackgroundLoadingChange(true);
      this.getUsersFromDB();
    } else if (filter === 'Query Sheet Assignment') {
      this.labelFilters = this.labelFilters.filter(f => f.name !== filter);

      let filterEvent = 'explorers'

      this.filterEvent.next(filterEvent)
      this.filters['qsassigned'] = '';
      this.onBackgroundLoadingChange(true);
      this.getUsersFromDB();
    }
    else{
      this.labelFilters = this.labelFilters.filter(f => f.name !== filter);

      if(filter === 'Role'){
        filter = 'roleid'
      }

      this.filterEvent.next(filter)
      this.filters[filter] = '';
      this.onBackgroundLoadingChange(true);
      this.getUsersFromDB();
    }
  }
}
